import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { Box } from "../Core";
// import { Link } from "gatsby";
import FooterLogo from "../Logo/FooterLogo";
import styles from '../../sections/landing3/style.module.scss'

// const TitleStyled = styled(Title)`
//   font-size: 16px;
//   font-weight: 700;
//   letter-spacing: -0.5px;
//   margin-bottom: 22px;
// `;

const UlStyled = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  li {
    line-height: 2.25;
    a {
      color: ${({ theme, color }) => theme.colors[color]} !important;
      &:hover {
        text-decoration: none;
        color: ${({ theme, color }) => theme.colors.secondary} !important;
      }
    }
  }
`;

const CopyRightArea = styled.div`
  border-top: ${({ dark, theme }) =>
    dark ? `1px solid #2f2f31 ` : `1px solid ${theme.colors.border}`};

  padding: 15px 0;
  p {
    color: ${({ dark, theme }) =>
    dark ? theme.colors.lightShade : theme.colors.darkShade};
    font-size: 13px;
    font-weight: 300;
    letter-spacing: -0.41px;
    line-height: 38px;
    margin-bottom: 0;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: inline-flex;
    a {
      color: ${({ dark, theme }) =>
    dark ? theme.colors.light : theme.colors.dark} !important;
      font-size: 16px;
      transition: 0.4s;
      padding: 0 3px;
      margin: 0 2.5px;
      &:visited {
        text-decoration: none;
      }
      &:hover {
        text-decoration: none;
        color: ${({ theme, color }) => theme.colors.secondary} !important;
      }
    }
  }
`;


const Footer = ({ isDark = true }) => {

  return (
    <>
      {/* <!-- Footer section --> */}
      <Box bg={isDark ? "dark" : "light"}>
        <Container>
          <Box
            css={`
              padding: 80px 0 60px;
            `}
          >
            {/* <Row className="justify-content-center">
              <Col lg="4" md="4">
                <FooterLogo white={isDark} style={{ width: '50%' }} />
              </Col> */}


            <Row>
              <Col lg="4" md="4">
                <FooterLogo white={isDark} style={{ width: '50%' }} />
              </Col>
              <Col lg="8" md="8">
                <Row>
                  <Col xs="12" lg="2">
                    <div
                      // className="mb-5 mb-lg-4"
                      className={`${styles.mobilemarginbottom}`}
                    >

                      <UlStyled color={isDark ? "lightShade" : "darkShade"}>
                        <li>

                          <a href="/terms">
                            Terms
                          </a>
                        </li>
                      </UlStyled>
                    </div>
                  </Col>
                  <Col xs="12" lg="2">
                    <div
                      // className="mb-5 mb-lg-4"
                      className={`${styles.mobilemarginbottom}`}
                    >

                      <UlStyled color={isDark ? "lightShade" : "darkShade"}>

                        <li>

                          <a href="/privacy">
                            Privacy
                          </a>
                        </li>
                      </UlStyled>
                    </div>
                  </Col>
                  <Col xs="12" lg="2" hidden>
                    <div
                      // className="mb-5 mb-lg-4"
                      className={`${styles.mobilemarginbottom}`}
                    >

                      <UlStyled color={isDark ? "lightShade" : "darkShade"}>

                        <li>
                          <a href="/#" target="_blank">
                            Help Center
                          </a>
                        </li>
                      </UlStyled>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Box>

          <CopyRightArea dark={isDark ? 1 : 0}>
            <Row className="align-items-center">
              <Col sm="6" className="text-sm-left text-center mb-2 mb-sm-0">
                <p>Copyrights 2021. Plento Inc.</p>
              </Col>
              <Col sm="6" className="text-sm-right text-center">
                <ul className="social-icons">
                  <li>
                    <a href="https://www.facebook.com/Plento-106837828317808" target="_blank" rel="noreferrer">
                      <i className="icon icon-logo-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/Plento_Inc" target="_blank" rel="noreferrer">
                      <i className="icon icon-logo-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/plentoinc" target="_blank" rel="noreferrer">
                      <i className="icon icon-logo-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/company/plento-inc" target="_blank" rel="noreferrer">
                      <i className="icon icon-logo-linkedin"></i>
                    </a>
                  </li>


                </ul>
              </Col>
            </Row>
          </CopyRightArea>
        </Container>
      </Box>
    </>
  );
};

export default Footer;
