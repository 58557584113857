import React from "react";
import { Link } from "gatsby";

import imgL1Logo from "../../assets/image/logo.svg";
import imgL1LogoWhite from "../../assets/image/logo-white.png";

const Logo = ({ white, height, className = "", ...rest }) => {
  return (
    <>
      <center>
        <Link to="/" className={`${className}`} {...rest}>
          {white ? (
            <img src={imgL1LogoWhite} alt="" style={{ width: '200px' }} />
          ) : (
            <img src={imgL1Logo} alt="" style={{ width: '200px' }} />
          )}
        </Link>
      </center>
    </>
  );
};

export default Logo;
